<template>
  <div class="agent-location">
    <div
      id="agentLocationMap"
      class="w-100"
      style="height: 80vh !important;"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */

import { showLoader, hideLoader } from '@/@core/comp-functions/ui/app'
import { useProviderUi } from '../provider/useProvider'
import { useStoreUi } from '../store/useStore'

export default {
  props: {
    agentId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      agentLocationMap: null,
      agentLoaded: null,
      mapImageStore: {
        STORE: require('@/assets/images/app-icons/store_New.png'),
        AGENT: require('@/assets/images/app-icons/agent.png'),
        HOUSE: require('@/assets/images/app-icons/house_New.png'),
      },
    }
  },
  mounted() {
    this.init()
    this.getAgentInfo()
  },
  methods: {
    init() {
      if (window.L) {
        if (this.agentLocationMap) {
          this.agentLocationMap.remove()
        }
        this.agentLocationMap = window.L.map('agentLocationMap').setView([-41.2858, 174.78682], 8)
        const mapLink = '<a href="http://openstreetmap.org">OpenStreetMap</a>'
        window.L.tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: `Map data &copy; ${mapLink}`,
          maxZoom: 18,
        }).addTo(this.agentLocationMap)
      }
    },
    getAgentInfo() {
      if (!this.agentId) {
        return
      }
      showLoader()
      const { getStoreAgentById } = useStoreUi()
      let apiCall = getStoreAgentById
      if (window.SuperApp.getters.isProvider()) {
        const { getProviderAgentById } = useProviderUi()
        apiCall = getProviderAgentById
      }
      apiCall(this.agentId).then(({ data }) => {
        hideLoader()
        if (data.statusCode === '200') {
          this.agentLoaded = window.SuperApp.actions.cloneDeep(data.responseData)
          if (data.responseData && data.responseData.latitude && data.responseData.longitude) {
            if (window.individualMarker && Array.isArray(window.individualMarker)) {
              window.individualMarker.forEach(item => item.remove())
              window.individualMarker = []
            }
            window.SuperApp.actions.addMarker([`${this.agentLoaded.latitude}, ${this.agentLoaded.longitude}`], [this.mapImageStore.AGENT], this.agentLocationMap, 'individualMarker')
          }
        }
        this.$nextTick(() => {
            window.SuperApp.helper.resize()
        })
      })
    },
  },
}
</script>
