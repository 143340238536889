<template>
  <div
    v-if="that"
    class="wrap-request-items"
  >
    <b-card
      class="ecommerce-card mb-0 dashboard-cards"
      no-body
    >
      <b-card-body>
        <div class="d-flex align-items-center justify-content-between">
          <b-badge
            pill
            variant="light-primary"
            class="mb-25"
          >
            {{ props.booking_id }}
          </b-badge>
          <a
            href="javascript: void(0);"
            class="d-flex wrap-dashboard-card-options text-dark"
          >
            <b-img
              :src="require('@/assets/images/app-icons/print.png')"
              class="mr-mobile-0"
              @click="printShopPdf(props)"
            />
            <p class="mb-0 font-12 d-none-mobile">
              {{ $t('PRINT') }}
            </p>
          </a>
        </div>
        <p class="mb-0 font-10">
          {{ $t('REQUEST') }}
        </p>
        <div class="mb-50 font-weight-bold font-14 fix-scroll-req-card">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="p-0">
                  #
                </th>
                <th class="p-0">
                  Service
                </th>
                <th class="p-0">
                  Price
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, key) of that.getCategoryArray(props)"
                :key="key"
              >
                <td class="p-0">
                  {{ key + 1 }}.
                </td>
                <td class="p-0">
                  {{ item.service }}
                </td>
                <td class="p-0">
                  {{ item.currency_symbol }} {{ item.total_service_price }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <p class="mb-0">
          {{ $t('Status') }}: {{ props.status }}
        </p>
        <p class="mb-0">
          {{ $t('Agent') }}: {{ props.agent ? props.agent.name : 'N/A' }}
        </p>
        <div class="row text-center mt-1 justify-content-around">
          <div v-if="props.timeslot" class="col-6 request-date-price">
            <p class="mb-25">
              {{ $t('Scheduled Date') }}
            </p>
            <p class="mb-0">
              {{ props.timeslot.booking_date }}
            </p>
          </div>
          <div class="col-6 request-date-price">
            <p class="mb-25">
              {{ $t('Amount') }}
            </p>
            <p class="mb-0">
              {{ that.getServicePrice(props) }}
            </p>
          </div>
        </div>
        <div class="d-flex text-center mt-1 justify-content-around">
          <b-button
            variant="primary"
            class="mr-1"
            @click="that.viewServiceHistory(props)"
          >
            {{ $t('View') }}
          </b-button>
          <b-button
            variant="success"
            class="mr-1"
            @click="that.updateRequestApproval(props, 'ACCEPTED')"
          >
            {{ $t('Accept') }}
          </b-button>
          <b-button
            variant="danger"
            @click="that.updateRequestApproval(props, 'CANCELLED')"
          >
            {{ $t('Reject') }}
          </b-button>
        </div>
      </b-card-body>
      <div v-if="!isEmpty(orderDetails)" id="invoice-POS2" class="d-none">
        <div style="max-width:400px;margin:0px auto">
          <div class="info">
            <div style="display: block; width: 100%; height: 60px">
              <div v-if="!isEmpty(orderDetails.provider.picture)" style="float: left; width: 25%; text-align: left">
                <img :src="`${$apiUrl}${orderDetails.provider.picture}`" style="" alt width="60" height="60">
              </div>
              <div style="float: left; width: 50%; text-align: center">
                <div style="font-size: 12px">Powered By</div>
                <div>
                  <img :src="`${$apiUrl}/images/common/mom_logo.png`" alt="" style="max-width: 100%">
                </div>
              </div>
              <div v-if="!isEmpty(orderDetails.provider.qrcode_url)" style="float: right; width: 25%; text-align: right">
                <img :src="`${$apiUrl}${orderDetails.provider.qrcode_url}`" style="" alt width="60" height="60">
              </div>
            </div>
            <div style="display: block; width: 100%; height: 1px"></div>
            <div style="display: block; width: 100%">
              <p>
                <span>{{$t('Booking ID')}} : {{orderDetails.booking_id}} <br></span>
                <span>{{$t('Date')}} : {{dateTimeFormat(orderDetails.created_at, "YYYY/MM/DD")}} <br></span>
                <span>{{$t('Provider Name')}} : {{orderDetails.provider.company_name}} <br></span>
                <span>{{$t('Provider Adress')}} : {{orderDetails.provider.current_location}} <br></span>
                <span>{{$t('Provider Email')}} : {{orderDetails.provider.email}} <br></span>
                <span>{{$t('Provider Phone')}} : {{orderDetails.provider.mobile}} <br></span>
                <span>{{$t('Customer')}} : {{orderDetails.user.first_name}} {{orderDetails.user.last_name}} <br></span>
              </p>
            </div>
          </div>

          <table class="table_data">
            <tbody>
              <tr v-for="item in orderDetails.cart_details" :key="`${item.id}`">
                <td colspan="3">
                    <div v-if="isMainServiceAvailable(item)">
                      <strong>{{ !isEmpty(item.provider_service.title) ? item.provider_service.title : item.provider_service.main_service.service_name }}</strong>
                    </div>
                    <div
                      v-if="isMainServiceAvailable(item) && item.provider_service.main_service.service_category"
                    >
                      {{ $t('Category') }}: {{ item.provider_service.main_service.service_category.service_category_name }}
                    </div>
                    <span>{{item.quantity}} X {{ currencyInfo }} {{ item.service_price }}</span>
                </td>
                <td
                  style="text-align:right;vertical-align:bottom"
                >
                  {{ currencyInfo }} {{ item.total_service_price }}
                </td>
              </tr>

              <tr style="margin-top:10px" v-show="orderDetails.payment">
                <td colspan="3" class="total">{{$t('Sub Total')}}</td>
                <td style="text-align:right;" class="total">{{ currencyInfo }} {{orderDetails.payment.fixed}}</td>
              </tr>

              <tr style="margin-top:10px" v-show="orderDetails.payment">
                <td colspan="3" class="total">{{$t('Discount')}}</td>
                <td style="text-align:right;" class="total">{{ currencyInfo }} {{orderDetails.payment.discount}}</td>
              </tr>

              <tr style="margin-top:10px" v-show="orderDetails.payment">
                <td colspan="3" class="total">{{$t('Tax')}}</td>
                <td style="text-align:right;" class="total">{{ currencyInfo }} {{orderDetails.payment.tax}}</td>
              </tr>

              <tr style="margin-top:10px">
                <td colspan="3" class="total">{{$t('Total')}}</td>
                <td
                  style="text-align:right;"
                  class="total"
                >
                  {{orderDetails.payment.total}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BButton } from 'bootstrap-vue'
import {isEmpty, dateTimeFormat} from "@/utilities"
import { useProviderUi } from '../useProvider'
import { showLoader } from '@/@core/comp-functions/ui/app';

export default {
  components: {
    BCard,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    that: {
      typeof: Object,
      default: () => {},
    },
    // eslint-disable-next-line vue/require-prop-types
    props: {
      typeof: Object,
      default: () => {},
    },
  },
  data: () => ({
    orderDetails: {}
  }),
  computed: {
    currencyInfo: {
      get(){
        if (isEmpty(this.orderDetails)) return null
        return this.orderDetails.currency
      }
    },
    getServiceType: {
      get(){
        let serviceType = ''
        if (this.orderDetails && this.orderDetails.service) {
          const service_name = !isEmpty(this.orderDetails.title) ? this.orderDetails.title : this.orderDetails.service.service_name;
          if (!isEmpty(this.orderDetails.service.service_category)) {
            serviceType = `${service_name} => ${this.orderDetails.service.service_category.service_category_name}`
          } else if (service_name) {
            serviceType = service_name
          }
        }
        if (serviceType) {
          return serviceType
        }
        if (!serviceType) {
          const payload = this.orderDetails
          if (
            payload
            && payload.items
            && payload.items[0]
            && payload.items[0].provider_service
            && payload.items[0].provider_service.main_service
            && payload.items[0].provider_service.main_service.service_category
          ) {
            serviceType = payload.items[0].provider_service.main_service.service_category.alias_name
          }
        }
        return serviceType || 'NA'
      }
    }
  },

  methods: {
    isEmpty, dateTimeFormat,
    isMainServiceAvailable(item) {
      return item.provider_service && item.provider_service.main_service
    },
    printShopPdf(payload) {
      showLoader()
      // this.orderDetails = payload
      const { providerRequestDetail } = useProviderUi()
      let orderDetails = {}
      this.orderDetails = orderDetails
      providerRequestDetail(payload.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            orderDetails = data.responseData.service
            orderDetails.cart_details = JSON.parse(orderDetails.cart_details)
            this.orderDetails = orderDetails
            setTimeout(() => {
              var divContents = document.getElementById("invoice-POS2").innerHTML;
              var a = window.open("", "", "height=500, width=500");
              a.document.write(
                '<link rel="stylesheet" href="/assets/css/pos_print.css"><html>'
              );
              a.document.write("<body >");
              a.document.write(divContents);
              a.document.write("</body></html>");
              a.document.close();
              setTimeout(() => {
                a.print();
              }, 300);
            }, 100);
          }
          hideLoader()
        })
        .catch(error => {
        })
    },
  }
}
</script>

<style>
.fix-scroll-req-card{
  max-height: 64px;
  overflow-y: scroll;
}
</style>
<style scoped>
.request-date-price {
  background: #f8f8f8;
  padding: 8px 5px;
}

.wrap-request-items {
  width: 25%;
  display: inline-block;
  margin-right: 0;
}
</style>

<style scoped>
.wrap-dashboard-card-options {
  border: 1px solid #ddd;
  padding: 2px 5px;
  border-radius: 4px;
}

.wrapper-order-cards {
  max-height: 300px;
  overflow-y: auto;
}

.grid-columns-template-wrapper {
  grid-template-columns: 50% 30% 20%;
  display: grid;
}

.wrap-dashboard-card-options img {
  height: 20px;
  margin-right: 5px;
}

.action-button-dashboard-cards {
  position: absolute;
  bottom: 15px;
  width: 91%;
}

.hidden-action-button-dashboard-cards {
  opacity: 0;
  pointer-events: none;
  z-index: -1;
}

.dashboard-cards {
  padding-bottom: 0;
  min-height: 320px;
}
</style>
